.active-class {
  color: #ffffff;
}

.form-control {
  background-color: white !important;
  color: #0070f3;
  height: 65px !important;
  width: 100% !important;
}
.react-tel-input .form-control {
  padding-left: 58px !important;
}
.react-tel-input {
  border-width: 0.5px !important;
  border-color: #0070f3 !important;
  border-radius: 6px;
}

.react-tel-input:focus {
  border-width: 1px !important;
  border-color: #0070f3 !important;
}

.flag-dropdown {
  background-color: white !important;
  width: 50px;
}

.setupKey {
  /* width: 230px; */
  text-align: center;
  font-size: 12px;
}
